import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL1 } from "../redux/apis";

// Define the Order interface
interface OrderItem {
  productId: number;
  productName: string;
  salePrice: number;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
}

interface Order {
  orderId: number;
  orderCode: string;
  customerId: number;
  shippingAddressId: number;
  billingAddressId: number;
  orderDate: string;
  totalAmount: number;
  orderStatus: "PENDING" | "SHIPPED" | "DELIVERED";
  paymentStatus: "PENDING" | "PAID";
  orderItems: OrderItem[];
}

// Define the state for the order slice
interface OrderState {
  currentOrder: Order | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

// Initial state for order slice
const initialState: OrderState = {
  currentOrder: null,
  status: "idle",
  error: null,
};

const token = sessionStorage.getItem("token");

const BASE_URL = `${API_BASE_URL1}/orders`;

// Fetch all orders
export const fetchAllOrders = createAsyncThunk(
  "order/fetchAllOrders",
  async () => {
    const response = await axios.get(
      `${BASE_URL}?offset=0&limit=10&sortingField=1`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  }
);

// Fetch a single order by its ID
export const fetchOrderById = createAsyncThunk(
  "order/fetchOrderById",
  async (orderId: number) => {
    const response = await axios.get(`${BASE_URL}/${orderId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  }
);

// Create a new order
export const createOrder = createAsyncThunk(
  "order/createOrder",
  async (orderData: Order) => {
    const response = await axios.post(BASE_URL, orderData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  }
);

// Update an existing order
export const updateOrder = createAsyncThunk(
  "order/updateOrder",
  async (orderData: Order) => {
    const response = await axios.put(
      `${BASE_URL}/${orderData.orderId}`,
      orderData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  }
);

// fetchOrdersByCustomerId to keep it for customer-specific orders
export const fetchOrdersByCustomerId = createAsyncThunk(
  "order/fetchOrdersByCustomerId",
  async (customerId: number) => {
    const response = await axios.get(`${BASE_URL}/customer/${customerId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  }
);

// Delete an order by its ID
export const deleteOrder = createAsyncThunk(
  "order/deleteOrder",
  async (orderId: number) => {
    await axios.delete(`${BASE_URL}/${orderId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return orderId;
  }
);

// Slice definition for managing order state
export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    // Action to clear current order state
    clearCurrentOrder: (state) => {
      state.currentOrder = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handling fulfilled fetchOrderById
      .addCase(
        fetchOrderById.fulfilled,
        (state, action: PayloadAction<Order>) => {
          state.currentOrder = action.payload;
          state.status = "succeeded";
        }
      )

      // Handling fulfilled fetchAllOrders
      .addCase(
        fetchAllOrders.fulfilled,
        (state, action: PayloadAction<Order[]>) => {
          state.currentOrder = action.payload[0];
          state.status = "succeeded";
        }
      )

    // Handling fulfilled fetchOrdersByCustomerId
    .addCase(
      fetchOrdersByCustomerId.fulfilled,
      (state, action: PayloadAction<Order[]>) => {
        state.currentOrder = action.payload[0];
        state.status = "succeeded";
      }
    )

    .addCase(fetchOrderById.pending, (state) => {
      state.status = "loading";
    })
    .addCase(fetchOrderById.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message || "Failed to fetch order";
    })

    .addCase(fetchAllOrders.pending, (state) => {
      state.status = "loading";
    })
   .addCase(fetchAllOrders.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message || "Failed to fetch all orders";
    })

    .addCase(fetchOrdersByCustomerId.pending, (state) => {
      state.status = "loading";
    })
    .addCase(fetchOrdersByCustomerId.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message || "Failed to fetch customer orders";
    })
    
    // Handling fulfilled createOrder
    .addCase(
      createOrder.fulfilled,
      (state, action: PayloadAction<Order>) => {
        state.currentOrder = action.payload;
        state.status = "succeeded";
      }
    )

    // Handling pending createOrder
    .addCase(createOrder.pending, (state) => {
      state.status = "loading";
    })

    // Handling rejected createOrder
    .addCase(createOrder.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message || "Failed to create order";
    })

    // Handling fulfilled updateOrder
    .addCase(
      updateOrder.fulfilled,
      (state, action: PayloadAction<Order>) => {
        state.currentOrder = action.payload;
        state.status = "succeeded";
      }
    )

    // Handling pending updateOrder
    .addCase(updateOrder.pending, (state) => {
      state.status = "loading";
    })

    // Handling rejected updateOrder
   .addCase(updateOrder.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message || "Failed to update order";
    })

    // Handling fulfilled deleteOrder
    .addCase(
      deleteOrder.fulfilled,
      (state, action: PayloadAction<number>) => {
        if (state.currentOrder?.orderId === action.payload) {
          state.currentOrder = null;
        }
        state.status = "succeeded";
      }
    )

    // Handling pending deleteOrder
    .addCase(deleteOrder.pending, (state) => {
      state.status = "loading";
    })

    // Handling rejected deleteOrder
    .addCase(deleteOrder.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message || "Failed to delete order";
    });
  },
});

export const { clearCurrentOrder } = orderSlice.actions;

// Selectors
export const selectCurrentOrder = (state: { order: OrderState }) =>
  state.order.currentOrder;
export const selectOrderStatus = (state: { order: OrderState }) =>
  state.order.status;
export const selectOrderError = (state: { order: OrderState }) =>
  state.order.error;

export default orderSlice.reducer;
