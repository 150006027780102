import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  Button,
  TextField,
} from "@mui/material";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

const HEIGHT = window.innerHeight;
const WIDTH = window.innerWidth;

const ScheduleInstallation = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    address: "",
    preferredDate: "",
    additionalNotes: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Placeholder for form submission logic
    alert("Your installation request has been submitted!");
  };

  return (
    <>
      <Header />
      <Box
        style={{
          width: (WIDTH * 90) / 100,
          marginLeft: (WIDTH * 5) / 100,
          paddingTop: (HEIGHT * 5) / 100,
          borderRadius: 10,
          backgroundColor: "white",
          marginBottom: (HEIGHT * 6) / 100,
          paddingBottom: (HEIGHT * 10) / 100,
        }}
      >
        <Typography
          variant="h4"
          style={{ textAlign: "center", marginBottom: (HEIGHT * 4) / 100 }}
        >
          Schedule Your CCTV Installation
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 4 }}>
              <Typography variant="h6" gutterBottom>
                Fill out the form below to schedule your installation
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Full Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
                <TextField
                  label="Installation Address"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
                <TextField
                  label="Preferred Installation Date"
                  type="date"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="preferredDate"
                  value={formData.preferredDate}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  required
                />
                <TextField
                  label="Additional Notes (optional)"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="additionalNotes"
                  value={formData.additionalNotes}
                  onChange={handleChange}
                  multiline
                  rows={4}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: (HEIGHT * 2) / 100 }}
                >
                  Submit Request
                </Button>
              </form>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginTop: (HEIGHT * 2) / 100 }}
              >
                Our team will contact you shortly to confirm your installation details.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default ScheduleInstallation;
