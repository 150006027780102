import React from "react";
import { Box, Typography, IconButton, Grid } from "@mui/material";
import {
  Facebook,
  Twitter,
  LinkedIn,
  Instagram,
  YouTube,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <Box
      sx={{ bgcolor: "background.paper", p: 6, borderTop: "1px solid #e0e0e0" }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={3.6}>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            Credenze
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            Best information about the company goes here but now lorem ipsum is.
            Best information about the company goes here but now lorem ipsum is.
            Best information about the company goes here but now lorem ipsum is.
          </Typography>
          <Box>
            <IconButton color="inherit" href="https://www.facebook.com">
              <Facebook />
            </IconButton>
            <IconButton color="inherit" href="https://www.twitter.com">
              <Twitter />
            </IconButton>
            <IconButton color="inherit" href="https://www.linkedin.com">
              <LinkedIn />
            </IconButton>
            <IconButton color="inherit" href="https://www.instagram.com">
              <Instagram />
            </IconButton>
            <IconButton color="inherit" href="https://www.youtube.com">
              <YouTube />
            </IconButton>
          </Box>
        </Grid>

        {[
          {
            title: "About",
            links: [
              { name: "About Us", path: "/aboutus" },
              { name: "Find Store", path: "/" },
              { name: "Categories", path: "/" },
              { name: "Blogs", path: "/blogs" },
            ],
          },
          {
            title: "Partnership",
            links: [
              { name: "About Us", path: "/aboutus" },
              { name: "Find Store", path: "/" },
              { name: "Categories", path: "/" },
              { name: "Blogs", path: "/blogs" },
            ],
          },
          {
            title: "Information",
            links: [
              { name: "Help Center", path: "/helpcenter" },
              { name: "Money Refund", path: "/moneyrefund" },
              { name: "Shipping", path: "/afterpurchase" },
              { name: "Contact us", path: "/contactus" },
            ],
          },
          {
            title: "For users",
            links: [
              { name: "Login", path: "/mycart1" },
              { name: "Register", path: "/otpverification" },
              { name: "Settings", path: "/settings" },
              { name: "My Orders", path: "/usercart" },
            ],
          },
        ].map((section) => (
          <Grid item xs={12} md={1.6} key={section.title}>
            <Typography variant="h6" color="textPrimary" gutterBottom>
              {section.title}
            </Typography>
            {section.links.map((link) => (
              <Typography
                key={link.name}
                variant="body2"
                color="textSecondary"
                style={{ cursor: "pointer" }}
                onClick={() => handleNavigation(link.path)}
              >
                {link.name}
              </Typography>
            ))}
          </Grid>
        ))}

        <Grid item xs={12} md={2}>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            Get app
          </Typography>
          <Box>
            <a href="https://www.apple.com/app-store/">
              <img
                src={require("../../../assets/Home/appstore.jpg")}
                alt="App Store"
                style={{ width: 140, marginRight: 8 }}
              />
            </a>
            <a href="https://play.google.com/store">
              <img
                src={require("../../../assets/Home/playstore.jpg")}
                alt="Google Play Store"
                style={{ width: 140 }}
              />
            </a>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
