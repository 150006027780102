import React from "react";
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

const HEIGHT = window.innerHeight;
const WIDTH = window.innerWidth;

const blogPosts = [
  {
    id: 1,
    title: "5 Must-Have Security Cameras for Your Home",
    img: require("../../../assets/Home/Cctv.png"),
    snippet:
      "Choosing the right security camera is crucial for your home’s safety. Here are five of the best cameras from Credenze to keep your home secure.",
    link: "/blogs/5-must-have-security-cameras",
  },
  {
    id: 2,
    title: "How to Set Up Your Credenze Camera System",
    img: require("../../../assets/Home/Cctv.png"),
    snippet:
      "Learn how to easily install and set up your new Credenze camera system in just a few simple steps.",
    link: "/blogs/how-to-setup-camera-system",
  },
  {
    id: 3,
    title: "The Benefits of AI-Powered Surveillance Cameras",
    img: require("../../../assets/Home/Cctv.png"),
    snippet:
      "AI-powered cameras are revolutionizing the surveillance industry. Discover how Credenze integrates AI technology to provide smarter security solutions.",
    link: "/blogs/ai-powered-surveillance",
  },
];

const Blogs = () => {
  return (
    <>
      <Header />
      <Box
        style={{
          width: (WIDTH * 90) / 100,
          marginLeft: (WIDTH * 5) / 100,
          paddingTop: (HEIGHT * 5) / 100,
          borderRadius: 10,
          backgroundColor: "white",
          marginBottom: (HEIGHT * 6) / 100,
        }}
      >
        <Typography
          variant="h4"
          style={{ textAlign: "center", marginBottom: (HEIGHT * 4) / 100 }}
        >
          Credenze Blogs
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {blogPosts.map((post) => (
            <Grid item xs={12} md={4} key={post.id}>
              <Card>
                <CardMedia
                  component="img"
                  height="200"
                  image={post.img}
                  alt={post.title}
                />
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {post.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ marginBottom: (HEIGHT * 2) / 100 }}
                  >
                    {post.snippet}
                  </Typography>
                  <Button variant="contained" color="primary" href={post.link}>
                    Read More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default Blogs;
