import React from "react";
import { Container, Typography, Box, Paper, Grid, Button } from "@mui/material";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { useNavigate } from "react-router-dom";

const HEIGHT = window.innerHeight;
const WIDTH = window.innerWidth;

const InstallationService = () => {
const navigate = useNavigate();

const handleScheduleInstallation = () => {
  navigate("/scheduleinstallation");
};

  return (
    <>
      <Header />
      <Box
        style={{
          width: (WIDTH * 90) / 100,
          marginLeft: (WIDTH * 5) / 100,
          paddingTop: (HEIGHT * 5) / 100,
          borderRadius: 10,
          backgroundColor: "white",
          marginBottom: (HEIGHT * 6) / 100,
          paddingBottom: (HEIGHT * 10) / 100,
        }}
      >
        <Typography
          variant="h4"
          style={{ textAlign: "center", marginBottom: (HEIGHT * 4) / 100 }}
        >
          CCTV Installation Services
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 4 }}>
              <Typography variant="h6" gutterBottom>
                Professional CCTV Installation by Credenze
              </Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: (HEIGHT * 2) / 100 }}
              >
                Ensure the safety and security of your premises with Credenze's
                expert CCTV installation services. Our team of certified
                technicians will assess your location, recommend the best
                solutions, and install high-quality surveillance systems
                tailored to your needs.
              </Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: (HEIGHT * 2) / 100 }}
              >
                From single-camera setups to complex multi-camera
                configurations, we provide comprehensive services that include
                consultation, installation, configuration, and training on how
                to use your new system.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: (HEIGHT * 2) / 100 }}
                onClick={handleScheduleInstallation}
              >
                Schedule Installation
              </Button>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginTop: (HEIGHT * 2) / 100 }}
              >
                Note: Our installations come with a full warranty and 24/7
                support. Contact Credenze to learn more about our maintenance
                plans and ensure uninterrupted surveillance.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default InstallationService;
