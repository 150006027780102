import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homes from '../screens/Homes/HomePage';
import MyCart from '../screens/MyCart/MyCart';
import MyCart1 from '../screens/ConfirmOrder/ConfirmOrder';
import ProductDetail from '../screens/ProductDetail/ProductDetail';
import ProductList from '../screens/ProductList/ProductList';
import ProductList1 from '../screens/ProductList1/ProductList';
import UserCart from '../screens/UserCart/UserCart';
import AfterPurchase from '../screens/AfterPurchase/AfterPurchase';
import OTPVerification from '../screens/OtpVerification/OtpVerification';
import ProfileDetails from '../screens/ProfileDetails/ProfileDetails';
import Aboutus from '../screens/Aboutus/Aboutus';
import ContactUs from '../screens/ContactUs/ContactUs';
import Blogs from '../screens/Blogs/Blogs';
import HelpCenter from '../screens/HelpCenter/HelpCenter';
import MoneyRefund from '../screens/MoneyRefund/MoneyRefund';
import Settings from '../screens/Settings/Settings';
import ProfilePage from '../screens/ProfilePage/ProfilePage';
import TrackNow from '../screens/TrackNow/TrackNow';
import InstallationService from '../screens/InstallationService/InstallationService';
import ScheduleInstallation from '../screens/ScheduleInstallation/ScheduleInstallation';
import ConfirmOrder from '../screens/ConfirmOrder/ConfirmOrder';

const AppRouter: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" Component={Homes} />
        <Route path="/mycart" Component={MyCart} />
        <Route path="/productdetail" Component={ProductDetail} />
        <Route path="/productlist" Component={ProductList} />
        <Route path="/productlist1" Component={ProductList1} />
        <Route path="/usercart" Component={UserCart} />
        <Route path="/order-success" Component={AfterPurchase} />
        <Route path="/otpverification" Component={OTPVerification} />
        <Route path="/profiledetails" Component={ProfileDetails} />
        <Route path="/aboutus" Component={Aboutus} />
        <Route path="/contactus" Component={ContactUs} />
        <Route path="/blogs" Component={Blogs} />
        <Route path="/helpcenter" Component={HelpCenter} />
        <Route path="/moneyrefund" Component={MoneyRefund} />
        <Route path="/settings" Component={Settings} />
        <Route path="/profilepage" Component={ProfilePage} />
        <Route path="/tracknow" Component={TrackNow} />
        <Route path="/installationservice" Component={InstallationService} />
        <Route path="/scheduleinstallation" Component={ScheduleInstallation} />
        <Route path="/confirm-order" Component={ConfirmOrder} />
      </Routes>
    </Router>
  );



};

export default AppRouter;

