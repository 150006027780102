import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

const HelpCenter = () => {
  return (
    <>
      <Header />
      <Container style={{ padding: "20px" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Credenze Help Center
        </Typography>

        <Box my={4}>
          <Typography variant="h5" gutterBottom>
            Frequently Asked Questions (FAQs)
          </Typography>
          <Paper style={{ padding: "20px" }}>
            <List>
              <ListItem>
                <ListItemText
                  primary="How do I set up my Credenze camera?"
                  secondary="Setting up your Credenze camera is easy. Simply follow the instructions in the user manual provided in the box."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="What should I do if my camera is not connecting?"
                  secondary="Ensure that your camera is within range of your Wi-Fi network and that the network credentials are correct. If issues persist, try resetting the camera."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Where can I find the user manual?"
                  secondary="The user manual is available for download on our website under the 'Support' section or included in the product packaging."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="How can I contact customer support?"
                  secondary="You can reach our customer support team via email at support@credenze.com or call us at 1-800-555-0199."
                />
              </ListItem>
            </List>
          </Paper>
        </Box>

        <Box my={4}>
          <Typography variant="h5" gutterBottom>
            Additional Resources
          </Typography>
          <Paper style={{ padding: "20px" }}>
            <List>
              <ListItem>
                <ListItemText
                  primary="User Manuals"
                  secondary="Access the user manuals for all our products."
                />
                <Button
                  variant="contained"
                  color="primary"
                  href="/support/user-manuals"
                  style={{ marginLeft: "10px" }}
                >
                  View Manuals
                </Button>
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Video Tutorials"
                  secondary="Watch our step-by-step video tutorials to help you set up and use your camera."
                />
                <Button
                  variant="contained"
                  color="primary"
                  href="/support/tutorials"
                  style={{ marginLeft: "10px" }}
                >
                  Watch Tutorials
                </Button>
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Warranty Information"
                  secondary="Find out about our warranty policies for all Credenze products."
                />
                <Button
                  variant="contained"
                  color="primary"
                  href="/support/warranty"
                  style={{ marginLeft: "10px" }}
                >
                  Learn More
                </Button>
              </ListItem>
            </List>
          </Paper>
        </Box>

        <Box my={4}>
          <Typography variant="h5" gutterBottom>
            Contact Us
          </Typography>
          <Paper style={{ padding: "20px" }}>
            <Typography variant="body1">
              If you have any further questions or need assistance, feel free to reach out to us:
            </Typography>
            <Typography variant="body1">
              Email: <strong>support@credenze.com</strong>
            </Typography>
            <Typography variant="body1">
              Phone: <strong>1-800-555-0199</strong>
            </Typography>
            <Typography variant="body1">
              Our support team is available from Monday to Friday, 9 AM to 5 PM.
            </Typography>
          </Paper>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default HelpCenter;
