import React from "react";
import { Container, Typography, Box, Paper, Grid } from "@mui/material";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

const HEIGHT = window.innerHeight;
const WIDTH = window.innerWidth;

const AboutUs = () => {
  return (
    <>
      <Header />
      <Box
        style={{
          width: (WIDTH * 90) / 100,
          marginLeft: (WIDTH * 5) / 100,
          paddingTop: (HEIGHT * 5) / 100,
          borderRadius: 10,
          backgroundColor: "white",
          marginBottom: (HEIGHT * 6) / 100,
          paddingBottom: (HEIGHT * 10) / 100,
        }}
      >
        <Typography
          variant="h4"
          style={{ textAlign: "center", marginBottom: (HEIGHT * 4) / 100 }}
        >
          About Credenze
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={8}>
            <Paper elevation={3} sx={{ p: 4 }}>
              <Typography variant="h6" gutterBottom>
                Welcome to Credenze
              </Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: (HEIGHT * 2) / 100 }}
              >
                Credenze is a leading provider of high-quality surveillance and
                camera solutions, offering the latest technology to keep your
                home, business, and loved ones safe. Our range of products
                includes cutting-edge AI-powered cameras, indoor and outdoor
                surveillance systems, and much more.
              </Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: (HEIGHT * 2) / 100 }}
              >
                Founded in 2010, Credenze has been dedicated to innovation,
                quality, and customer satisfaction. Our mission is to make
                security and surveillance accessible to everyone, with reliable
                products that meet your specific needs.
              </Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: (HEIGHT * 2) / 100 }}
              >
                We believe in delivering not just products, but peace of mind.
                Whether you're securing your home or managing a large-scale
                security system for your business, our team is here to support
                you every step of the way.
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                style={{ marginTop: (HEIGHT * 4) / 100 }}
              >
                Our Vision and Values
              </Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: (HEIGHT * 2) / 100 }}
              >
                At Credenze, our vision is to become a global leader in security
                solutions, driven by our core values of integrity, innovation,
                and excellence. We are committed to providing exceptional
                customer service and continually improving our products to meet
                the evolving demands of the industry.
              </Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: (HEIGHT * 2) / 100 }}
              >
                Our products are trusted by thousands of customers across the
                globe, and we pride ourselves on being at the forefront of
                technological advancements in surveillance.
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                style={{ marginTop: (HEIGHT * 4) / 100 }}
              >
                Why Choose Us?
              </Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: (HEIGHT * 2) / 100 }}
              >
                - Over a decade of experience in the surveillance industry.
              </Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: (HEIGHT * 2) / 100 }}
              >
                - State-of-the-art camera and security solutions.
              </Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: (HEIGHT * 2) / 100 }}
              >
                - Exceptional customer support and service.
              </Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: (HEIGHT * 2) / 100 }}
              >
                - Competitive pricing and a wide range of products to meet
                diverse needs.
              </Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: (HEIGHT * 2) / 100 }}
              >
                At Credenze, we don’t just sell products – we build long-lasting
                relationships with our customers.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default AboutUs;
