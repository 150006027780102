import React from "react";
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  TextField,
  Button,
  Switch,
  FormControlLabel,
} from "@mui/material";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

const HEIGHT = window.innerHeight;
const WIDTH = window.innerWidth;

const Settings = () => {
  return (
    <>
      <Header />
      <Box
        style={{
          width: (WIDTH * 90) / 100,
          marginLeft: (WIDTH * 5) / 100,
          paddingTop: (HEIGHT * 5) / 100,
          borderRadius: 10,
          backgroundColor: "white",
          marginBottom: (HEIGHT * 6) / 100,
          paddingBottom: (HEIGHT * 10) / 100,
        }}
      >
        <Typography
          variant="h4"
          style={{ textAlign: "center", marginBottom: (HEIGHT * 4) / 100 }}
        >
          User Settings
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 4 }}>
              <Typography variant="h6" gutterBottom>
                Update Your Preferences
              </Typography>
              <TextField
                label="Username"
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Mobile Number"
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Email Address"
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <FormControlLabel
                control={<Switch defaultChecked color="primary" />}
                label="Receive Email Notifications"
              />
              <FormControlLabel
                control={<Switch color="primary" />}
                label="Enable Dark Mode"
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: (HEIGHT * 2) / 100 }}
              >
                Save Settings
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default Settings;
