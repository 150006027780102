import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  Button,
  Divider,
} from "@mui/material";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { useNavigate } from "react-router-dom";
import { GET_ORDERS_BY_CUSTOMER } from "../../redux/apis";

const HEIGHT = window.innerHeight;
const WIDTH = window.innerWidth;

const ProfilePage = () => {
 const history = useNavigate();
 const [data, setData] = useState<any>();


 const getOrderByCustomer = async () => {
   var token = localStorage.getItem("token");
   var id = localStorage.getItem("customerId")
     ? localStorage.getItem("customerId")
     : "0";
   await fetch(`${GET_ORDERS_BY_CUSTOMER}${id}`, {
     method: "GET",
     headers: {
       "Access-Control-Allow-Origin": "*",
       "Content-Type": "application/json",
       Authorization: `Bearer ${token}`,
     },
   })
     .then((response) => {
       console.log(response, "LLLLLLLLLLLLL");
       return response.text();
     })
     .then((text) => {
       if (text) {
         try {
           return JSON.parse(text);
         } catch (error) {
           console.error("Invalid JSON:", error);
           return null;
         }
       } else {
         console.warn("Empty response");
         return null;
       }
     })
     .then((data) => {
       if (data) {
         console.log(data, "KKKKKKKKKKKKKKKKKKKK");
         setData(data);
       } else {
         console.log("No data returned");
       }
     })
     .catch((error) => console.error("Fetch error:", error));
 };



  return (
    <>
      <Header />
      <Box
        style={{
          width: (WIDTH * 90) / 100,
          marginLeft: (WIDTH * 5) / 100,
          paddingTop: (HEIGHT * 5) / 100,
          borderRadius: 10,
          backgroundColor: "white",
          marginBottom: (HEIGHT * 6) / 100,
          paddingBottom: (HEIGHT * 10) / 100,
        }}
      >
        <Typography
          variant="h4"
          style={{ textAlign: "center", marginBottom: (HEIGHT * 4) / 100 }}
        >
          Profile
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={8}>
            <Paper elevation={3} sx={{ p: 4 }}>
              {/* User Information */}
              <Typography variant="h6" gutterBottom>
                User Information
              </Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: (HEIGHT * 2) / 100 }}
              >
                Name: John Doe
              </Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: (HEIGHT * 2) / 100 }}
              >
                Email: john.doe@example.com
              </Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: (HEIGHT * 2) / 100 }}
              >
                Member since: January 2022
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginBottom: (HEIGHT * 4) / 100 }}
              >
                Edit Profile
              </Button>

              {/* Order Summary */}
              <Grid item xs={12} lg={12}>
                <Box mt={4}>
                  <Paper elevation={3} sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={8}>
                        <Typography variant="h6">Order Summary</Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography variant="h6" align="right">
                          Year: 2024
                        </Typography>
                      </Grid>
                    </Grid>

                    <Divider sx={{ my: 2 }} />
                    <Box
                      sx={{
                        backgroundImage: `url(${require("../../../assets/bottom.jpg")})`,
                        backgroundSize: "stretch",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      <Grid container spacing={2}>
                        {[...Array(4)].map((_, index) => (
                          <React.Fragment key={index}>
                            <Grid item xs={12} sm={10}>
                              <Box display={"flex"}>
                                <img
                                  src={require("../../../assets/Home/Cctv.png")}
                                  alt="Product"
                                  style={{ marginRight: 16 }}
                                />
                                
                                <Box
                                  style={{ paddingLeft: (WIDTH * 0.4) / 100 }}
                                >
                                  <Typography variant="body1">
                                    8 MP Outdoor AI Surveillance Camera
                                  </Typography>
                                  <Box display={"flex"}>
                                    <Typography variant="body1">
                                      ₹125.00
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        marginLeft: "10px",
                                        color: "#DC2626",
                                      }}
                                    >
                                      ₹2000.00
                                    </Typography>
                                  </Box>
                                  <Typography variant="body1">
                                    Qty: 2
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <Typography
                                variant="body1"
                                align="right"
                                style={{ fontWeight: "700" }}
                              >
                                ₹4000.00
                              </Typography>
                            </Grid>
                          </React.Fragment>
                        ))}
                      </Grid>
                    </Box>
                  </Paper>
                </Box>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default ProfilePage;
