import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Checkbox,
  FormControlLabel,
  TextField,
  RadioGroup,
  IconButton,
  Link,
  Input,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import "./ConfirmOrder.css";
import { useNavigate } from "react-router-dom";
import {
  ADDRESS,
  GET_CURRENT_CUSTOMER,
  GET_OTP,
  VERIFY_OTP,
} from "../../redux/apis";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import OrderSummary from "../../components/OrderSummary/OrderSummary";

const HEIGHT = window.innerHeight;
const WIDTH = window.innerWidth;

// Define the structure of each address item
interface AddressData {
  createdBy: string;
  addressId: number;
  firstName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  district: string;
  state: string;
  country: string;
  pinCode: string;
  landMark: string;
  mobileNumber: string;
  alternateMobileNumber: string;
  isDefault?: boolean;
}
const ConfirmOrder = () => {
  const history = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [name, setName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [alternatePhone, setAlternatePhone] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [postal, setPostal] = useState("");
  const [landmark, setLandmark] = useState("");
  const [defau, setDefau] = useState(false);
  const [state, setState] = useState("");

  const [addressDatas, setAddressDatas] = useState<AddressData[]>([]);
  const [addressId, setAddressId] = useState(null);

  const [paymentData, setPaymentData] = useState<any>(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [cartData, setCartData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve and parse the cart data from session storage
    const savedCart = sessionStorage.getItem("cart");
    if (savedCart) {
      const parsedCart = JSON.parse(savedCart);
      setCartData(parsedCart.cartItems);
    }
  }, []);

  const handleSendOtp = async () => {
    const response = await fetch(`${GET_OTP}/${mobileNumber}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const customerId = sessionStorage.getItem("customerId");
    const firstName = sessionStorage.getItem("firstName");

    if (token && customerId && firstName) {
      setIsLoggedIn(true);
      getAddress();
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const getLogin = async () => {
    const response = await fetch(`${VERIFY_OTP}/${mobileNumber}/${otp}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.token) {
          sessionStorage.setItem("token", data.token);
          setSuccessMessage("Login successfully!");
          getMe(data.token);
          setIsLoggedIn(true); // Set isLoggedIn to true on successful login
        }
      })
      .catch((error) => console.error(error));
  };

  const getMe = async (val: any) => {
    const response = await fetch(`${GET_CURRENT_CUSTOMER}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${val}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        sessionStorage.setItem("customerId", data.customerId);
        sessionStorage.setItem("firstName", data.firstName);
        sessionStorage.setItem("lastName", data.lastName);
        sessionStorage.setItem("email", data.email);
        sessionStorage.setItem("mobileNumber", data.mobileNumber);
        history("/mycart1");
      })
      .catch((error) => console.error("Fetch error:", error));
  };

  // Fetch address
  const getAddress = async () => {
    const token = sessionStorage.getItem("token");
    const id = sessionStorage.getItem("customerId") || "0";
    const addressType = "CUSTOMER_BILLING";

    try {
      const response = await fetch(
        `${ADDRESS}/${id}?addressType=${encodeURIComponent(addressType)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseText = await response.text();
      console.log("Response Text:", responseText);

      if (responseText) {
        try {
          const addressData = JSON.parse(responseText);

          setAddressDatas(addressData.data);
          if (addressData.addressId) {
            setAddressId(addressData[0].addressId);
          }
        } catch (error) {
          console.error("Invalid JSON:", error);
        }
      } else {
        console.warn("Empty response");
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const handleNavigation = (path: string) => {
    history(path);
  };

  const handleClick = () => {
    history("/afterpurchase");
  };

  const statesAndUnionTerritories = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
  ];

  // const preventAccordionClose = (event: any) => {
  //   event.stopPropagation();
  // };

  const resetForm = () => {
    setName("");
    setAddress1("");
    setAddress2("");
    setAlternatePhone("");
    setCity("");
    setDistrict("");
    setPostal("");
    setLandmark("");
    setState("");
    setDefau(false);
  };

  const addAddress = async () => {
    const token = sessionStorage.getItem("token");
    const id = sessionStorage.getItem("customerId");

    let params = {
      contactId: id,
      addressId: 0,
      addressLine1: address1,
      addressLine2: address2,
      pinCode: postal,
      landMark: landmark,
      city: city,
      district: district,
      state: state,
      country: "INDIA",
      alternateMobileNumber: alternatePhone,
      isDefault: defau,
      addressType: "CUSTOMER_BILLING",
    };

    try {
      const response = await fetch(ADDRESS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(params),
      });

      const text = await response.text();
      console.log("Response text:", text);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = text ? JSON.parse(text) : null;
      if (result) {
        console.log(result, "Address added successfully.");
        resetForm();
      } else {
        console.warn("Received empty response body");
      }
    } catch (error) {
      console.error("Error adding address:", error);
    }
  };

  const updateAddress = async (addressId: any) => {
    const token = sessionStorage.getItem("token");
    const id = sessionStorage.getItem("customerId");

    let params = {
      contactId: id,
      addressId: addressId,
      addressLine1: address1,
      addressLine2: address2,
      pinCode: postal,
      landMark: landmark,
      city: city,
      district: district,
      state: state,
      country: "INDIA",
      alternateMobileNumber: alternatePhone,
      isDefault: defau,
      addressType: "CUSTOMER_BILLING",
    };

    try {
      const response = await fetch(`${ADDRESS}/${addressId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(params),
      });

      const text = await response.text();
      console.log("Response text:", text);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = text ? JSON.parse(text) : null;
      if (result) {
        console.log(result, "Address updated successfully.");
        resetForm();
      } else {
        console.warn("Received empty response body");
      }
    } catch (error) {
      console.error("Error updating address:", error);
    }
  };

  // Delete Address
  const deleteAddress = async (addressId: any) => {
    const token = sessionStorage.getItem("token");
    const arg1 = "CUSTOMER_BILLING";

    try {
      const response = await fetch(`${ADDRESS}/${addressId}?arg1=${arg1}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      console.log("Address deleted successfully");

      // Remove the deleted address from the state
      // setAddressDatas((prevAddressDatas) =>
      //   prevAddressDatas.filter((address) => address.addressId !== addressId)
      // );
      // setAddressId(null);
      // getAddress();
    } catch (error) {
      console.error("Error deleting address:", error);
    }
  };

  // Create order
  const createOrder = async () => {
    const orderUrl = "https://api.razorpay.com/v1/orders";
    const orderData = {
      amount: 50000,
      currency: "INR",
      receipt: "order_rcptid_11",
    };

    try {
      const response = await axios.post(orderUrl, orderData, {
        auth: {
          username: "rzp_test_qHpBRBwDfZ5fxZ",
          password: "TE0hOgMUovyV93XorR9D4Th4",
        },
      });
      setPaymentData(response.data);
      handlePayment();
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  const handlePayment = () => {
    // if (!paymentData) return;

    const options = {
      key: "rzp_test_qHpBRBwDfZ5fxZ",
      amount: paymentData && paymentData.amount ? paymentData.amount : 50000,
      currency:
        paymentData && paymentData.currency ? paymentData.currency : "INR",
      name: "Credenze",
      description: "Ecommerce",
      image: "https://your-logo-url.com",
      order_id:
        paymentData && paymentData.id ? paymentData.id : "order_OkkD22VfHlbYxc",
      handler: function (response: any) {
        console.log(response);
      },
      prefill: {
        name: "Rockstar",
        email: "rockstar.king@example.com",
        contact: "9898989898",
      },
      theme: {
        color: "#DC2626",
      },
    };
    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const placeOrder = () => {
    navigate("/order-success");
  };

  return (
    <>
      <Header />
      <Box style={{ width: (WIDTH * 90) / 100, marginLeft: (WIDTH * 5) / 100 }}>
        {/* <Typography variant="h5" style={{ margin: 11 }}>
          <strong>My Order</strong>
        </Typography> */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            {/* <MainContent prop={handleNavigation} /> */}

            {/* ............................................. */}

            <Box>
              {isLoggedIn ? (
                <Box>
                  <Typography variant="h5">
                    Welcome, {sessionStorage.getItem("firstName")}
                  </Typography>
                </Box>
              ) : (
                <Accordion defaultExpanded style={{ borderRadius: 10 }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} style={{ display: "flex" }}>
                        <img
                          src={require("../../../assets/usertick.png")}
                          alt="User Icon"
                        />
                        <Typography variant="h5" style={{ marginLeft: "20px" }}>
                          To Confirm Order? <a href="#">Click here to login</a>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Typography
                          variant="body2"
                          style={{ marginLeft: "50px" }}
                        >
                          If the user is already signed in, they will navigate
                          to the shipping address fields. New users need to log
                          in or create an account.
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "80%",
                        margin: "0 auto",
                        padding: "20px",
                      }}
                    >
                      <TextField
                        label="Enter your Mobile Number"
                        type="text"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={mobileNumber}
                        onChange={(event) =>
                          setMobileNumber(event.target.value)
                        }
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleSendOtp}
                      >
                        Send OTP
                      </Button>
                      <TextField
                        label="Enter OTP"
                        type="text"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={otp}
                        onChange={(event) => setOtp(event.target.value)}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 2, mb: 2 }}
                        onClick={getLogin}
                      >
                        LOGIN
                      </Button>
                      {successMessage && (
                        <Typography color="success.main" sx={{ mt: 2 }}>
                          {successMessage}
                        </Typography>
                      )}
                      <FormControlLabel
                        control={<Checkbox />}
                        label="You'll be able to save your details to create an account later."
                        sx={{ alignSelf: "flex-start", mb: 1 }}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Keep me up to date on exclusive offers"
                        sx={{ alignSelf: "flex-start", mb: 2 }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography variant="body2" color="textSecondary">
                          No Account yet?{" "}
                          <Link
                            href="/otpverification"
                            variant="body2"
                            color="primary"
                            style={{ marginLeft: "5px" }}
                          >
                            Register Free
                          </Link>
                        </Typography>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )}

              <Accordion
                style={{ marginTop: (HEIGHT * 4) / 100, borderRadius: 10 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ padding: (WIDTH * 1) / 100 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} style={{ display: "flex" }}>
                      <img src={require("../../../assets/maps.png")} />
                      <Typography variant="h5" style={{ marginLeft: "20px" }}>
                        Shipping Address
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography
                        variant="body2"
                        style={{ marginLeft: "50px" }}
                      >
                        If the user already signed Select Your Shipping Address.
                        You can add a New Shipping address or can Modify the
                        Existing one
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ p: WIDTH < 400 ? 0 : 5 }}>
                    <RadioGroup defaultValue="address1">
                      {/* <Box
                        sx={{
                          mb: 2,
                          p: 2,
                          border: "1px solid #ccc",
                          borderRadius: 1,
                        }}
                      >
                        <Box display="flex">
                          <FormControlLabel
                            value="address1"
                            control={
                              <Radio
                                style={{ marginTop: (-HEIGHT * 9) / 100 }}
                              />
                            }
                            label=""
                          />
                          <Box sx={{ ml: 1 }}>
                            <Typography
                              variant="body1"
                              style={{
                                fontWeight: "700",
                                marginLeft: (-WIDTH * 2) / 100,
                              }}
                            >
                              Deliver On This Address
                            </Typography>
                            <Box
                              style={{
                                marginLeft: (-WIDTH * 2) / 100,
                                marginTop: (HEIGHT * 2) / 100,
                              }}
                            >
                              <Typography style={{ fontWeight: "700" }}>
                                Rakesh Kumar
                              </Typography>
                              <Typography variant="body2">
                                Gopabandhu Nagar, Jayapur, Infront of State Bank
                                Of India, Andheri West, Mumbai
                              </Typography>
                              <Typography variant="body2">
                                Mob: 7045510247, Alternate Mob: 1234567890
                              </Typography>
                            </Box>
                          </Box>
                          <FormControlLabel
                            style={{ marginTop: (-HEIGHT * 9) / 100 }}
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "13px",
                              },
                            }}
                            control={<Checkbox sx={{ ml: "auto" }} />}
                            label="Set as default shipping address"
                          />
                        </Box>
                      </Box> */}

                      <Box
                        sx={{
                          mb: 2,
                          p: 2,
                          border: "1px solid #ccc",
                          borderRadius: 1,
                        }}
                      >
                        {addressDatas.length > 0 ? (
                          addressDatas.map((addressData, index) => (
                            <Box
                              key={index}
                              sx={{
                                mb: 2,
                                p: 2,
                                border: "1px solid #ddd",
                                borderRadius: 1,
                                backgroundColor: "#f9f9f9",
                                position: "relative",
                              }}
                            >
                              <FormControlLabel
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontSize: "13px",
                                  },
                                }}
                                control={<Checkbox />}
                                label="Set as default shipping address"
                              />
                              {/* Delete Button in Top-Right */}
                              <IconButton
                                sx={{
                                  position: "absolute",
                                  top: 8,
                                  right: 8,
                                }}
                                onClick={() =>
                                  deleteAddress(addressData.addressId)
                                } // Pass addressId to deleteAddress
                              >
                                <DeleteIcon />
                              </IconButton>

                              <Box display="flex">
                                <Box sx={{ ml: 1 }}>
                                  <Typography
                                    variant="body1"
                                    style={{ fontWeight: "700" }}
                                  >
                                    Deliver To This Address
                                  </Typography>
                                  <Box>
                                    <Typography style={{ fontWeight: "700" }}>
                                      {addressData.firstName}
                                    </Typography>
                                    <Typography variant="body2">
                                      {addressData.addressLine1},{" "}
                                      {addressData.addressLine2},{" "}
                                      {addressData.city}, {addressData.district}
                                      , {addressData.state},{" "}
                                      {addressData.country}
                                    </Typography>
                                    <Typography variant="body2">
                                      {`Pin Code: ${addressData.pinCode}, Landmark: ${addressData.landMark}`}
                                    </Typography>
                                    <Typography variant="body2">
                                      {`Mob: ${addressData.createdBy}, Alternate Mob: ${addressData.alternateMobileNumber}`}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          ))
                        ) : (
                          <Typography>No addresses found.</Typography>
                        )}
                      </Box>
                    </RadioGroup>

                    <Box sx={{ mt: 2 }}>
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ marginBottom: (HEIGHT * 1) / 100 }}
                      >
                        <IconButton
                          sx={{
                            backgroundColor: "white",
                            boxShadow: 3, // You can use the boxShadow prop to set the elevation
                            "&:hover": {
                              backgroundColor: "white",
                              boxShadow: 6, // Increase the shadow on hover
                            },
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                        <Typography style={{ marginLeft: (WIDTH * 1) / 100 }}>
                          Add New Address
                        </Typography>
                      </Box>
                      <Box
                        component="form"
                        sx={{
                          display: "grid",
                          gridTemplateColumns: { sm: "1fr 1fr" },
                          gap: 2,
                          mt: 2,
                        }}
                      >
                        <TextField
                          label="Full Name"
                          value={name}
                          onChange={(event) => setName(event.target.value)}
                          fullWidth
                        />
                        <TextField
                          label="Enter Flat, House No.,"
                          value={address1}
                          onChange={(event) => setAddress1(event.target.value)}
                          fullWidth
                        />
                        <TextField
                          label="Enter Building, Company"
                          value={address2}
                          onChange={(event) => setAddress2(event.target.value)}
                          fullWidth
                        />
                        <TextField
                          label="Enter Alternate Phone Number"
                          value={alternatePhone}
                          onChange={(event) =>
                            setAlternatePhone(event.target.value)
                          }
                          fullWidth
                        />
                        <TextField
                          label="Landmark"
                          value={landmark}
                          onChange={(event) => setLandmark(event.target.value)}
                          fullWidth
                        />
                        <Grid container spacing={2}>
                          <Grid item xs={6} md={6}>
                            <TextField
                              label="City"
                              value={city}
                              onChange={(event) => setCity(event.target.value)}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <TextField
                              label="District"
                              value={district}
                              onChange={(event) =>
                                setDistrict(event.target.value)
                              }
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={6} md={6}>
                            <TextField
                              label="Postal Code"
                              value={postal}
                              onChange={(event) =>
                                setPostal(event.target.value)
                              }
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <TextField
                              select
                              SelectProps={{ native: true }}
                              fullWidth
                              variant="outlined"
                              value={state}
                              onChange={(event) => setState(event.target.value)}
                            >
                              <option value="">Select State</option>
                              {statesAndUnionTerritories.map((state, index) => (
                                <option key={index} value={state}>
                                  {state}
                                </option>
                              ))}
                            </TextField>
                          </Grid>
                        </Grid>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={defau}
                              onChange={(event) =>
                                setDefau(event.target.checked)
                              }
                              name="checked"
                              color="primary"
                            // onClick={preventAccordionClose}
                            />
                          }
                          label="Set as default shipping address"
                          sx={{ mt: 2 }}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          sx={{ mt: 2, mb: 2 }}
                          onClick={addAddress}
                        >
                          SUBMIT
                        </Button>

                        {/* {successMessage && (
                          <Typography color="success.main" sx={{ mt: 2 }}>
                            {successMessage}
                          </Typography>
                        )} */}
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{ marginTop: (HEIGHT * 4) / 100, borderRadius: 10 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ padding: (WIDTH * 1) / 100 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} style={{ display: "flex" }}>
                      <img src={require("../../../assets/wall.png")} />
                      <Typography variant="h5" style={{ marginLeft: "20px" }}>
                        Payment Mode
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography
                        variant="body2"
                        style={{ marginLeft: "50px" }}
                      >
                        Please Select your Payment Method. You can store the
                        card to make the future shopping easy. We dont store the
                        confidential Details
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ p: 1 }}>
                    <Accordion defaultExpanded>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ display: "flex" }}
                          >
                            <Box display="flex" alignItems="center">
                              {/* <UPIIcon sx={{ mr: 1 }} /> */}
                              <img src={require("../../../assets/upi.png")} />
                              <Typography style={{ marginLeft: "10px" }}>
                                UPI
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ display: "flex" }}
                          >
                            <Typography
                              variant="body2"
                              style={{ marginLeft: (WIDTH * 2) / 100 }}
                            >
                              Pay directly from your bank account
                            </Typography>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* <Typography variant="body2">Pay directly from your bank account</Typography> */}
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Box display="flex" alignItems="center">
                          <img
                            src={require("../../../assets/creditcard.png")}
                          />
                          <Typography style={{ marginLeft: "10px" }}>
                            Credit or Debit
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box sx={{ p: WIDTH < 400 ? 0 : 3 }}>
                          <RadioGroup row defaultValue="credit">
                            <FormControlLabel
                              value="credit"
                              control={<Radio />}
                              label="Credit Card"
                            />
                            <FormControlLabel
                              value="debit"
                              control={<Radio />}
                              label="Debit Card"
                            />
                          </RadioGroup>
                          <Box
                            sx={{ mt: 2 }}
                            style={{
                              width: WIDTH < 400 ? "auto" : (WIDTH * 30) / 100,
                            }}
                          >
                            <TextField
                              label="Card Number"
                              fullWidth
                              margin="normal"
                            />
                            <Box display="flex" gap={2}>
                              <TextField
                                label="MM/YY"
                                margin="normal"
                                sx={{ flex: 1 }}
                              />
                              <TextField
                                label="CVV"
                                margin="normal"
                                sx={{ flex: 1 }}
                              />
                              {/* <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
                                                        <img src="visa-icon.png" alt="Visa" style={{ height: '24px' }} />
                                                    </Box> */}
                            </Box>
                            <TextField
                              label="Name on Card"
                              fullWidth
                              margin="normal"
                            />
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              sx={{ mt: 2 }}
                              onClick={createOrder}
                            >
                              PROCEED FOR PAYMENT
                            </Button>
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>

            {/* ............................................. */}
          </Grid>
          <Grid item xs={12} md={4} style={{ marginTop: (-HEIGHT * 3) / 100 }}>
            <OrderSummary cartData={cartData} buttonText={"PLACE ORDER"} handleNavigation={placeOrder} showCombo={false} />
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default ConfirmOrder;
