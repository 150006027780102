import React, { useEffect, useState } from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails,
  Checkbox, FormControlLabel, Slider, Button,
  Typography, Box, FormGroup
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GET_FILTERS, SEARCH_PRODUCTS } from '../../redux/apis';

const FilterSidebar: React.FC<any> = ({ updateData }) => {
  const [priceRange, setPriceRange] = useState<number[]>([1000, 1000000]);
  const [data, setData] = useState<any>();
  const [selectedBrands, setSelectedBrands] = useState<any[]>([]);
  const [selectedModels, setSelectedModels] = useState<any[]>([]);

  const handlePriceChange = (event: Event, newValue: number | number[]) => {
    setPriceRange(newValue as number[]);
  };

  useEffect(() => {
    getFilters();
  }, []);

  const getFilters = async () => {
    const response = await fetch(GET_FILTERS)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      })
      .catch((error) => console.error(error));
  };

  const handleBrandFilter = (e: any, item: any, index: number) => {
    if (e == 'on') {
      setSelectedBrands([...selectedBrands, item]);
    } else {
      const updatedList = selectedBrands.filter((_, i) => i !== index);
      setSelectedBrands(updatedList);
    }
  };

  const handleModelFilter = (e: any, item: any, index: number) => {
    if (e == 'on') {
      setSelectedModels([...selectedModels, item]);
    } else {
      const updatedList = selectedModels.filter((_, i) => i !== index);
      setSelectedModels(updatedList);
    }
  };

  const handleSubmit = async () => {
    let search_url = SEARCH_PRODUCTS + "?page=0&pageSize=12";
    let searchParams = "";
    if (priceRange && priceRange.length > 0) {
      searchParams = searchParams + "&minPrice=" + priceRange[0] + "&maxPrice=" + priceRange[1];
    }
    if (selectedBrands && selectedBrands.length > 0) {
      selectedBrands.forEach(brand => {
        searchParams = searchParams + "&brands=" + brand;
      })
    }
    if (selectedModels && selectedModels.length > 0) {
      selectedModels.forEach(model => {
        searchParams = searchParams + "&models=" + model;
      })
    }
    updateData(searchParams);
  }

  return (
    <Box sx={{ width: 300, p: 2, border: '1px solid #e0e0e0', borderRadius: '8px' }}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: 'bold' }}>Brands</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {data && data.Brand && data.Brand.length > 0 &&
              data.Brand.map((brand: any, index: any) => (
                <FormControlLabel key={brand} control={<Checkbox
                  onClick={(e: any) => handleBrandFilter(e.target.value, brand, index)} />} label={brand} />
              ))
            }
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: 'bold' }}>Models</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {data && data.Model && data.Model.length > 0 &&
              data.Model.map((model: any, index: any) => (
                <FormControlLabel key={model} control={<Checkbox onClick={(e: any) => handleModelFilter(e.target.value, model, index)} />} label={model} />
              ))
            }
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: 'bold' }}>Filter By Price</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography gutterBottom>Price: </Typography>
          <Slider
            value={priceRange}
            onChange={handlePriceChange}
            valueLabelDisplay="auto"
            min={2000}
            max={30000}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>₹{priceRange[0]}</Typography>
            <Typography>₹{priceRange[1]}</Typography>
          </Box>
          <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
            Filter
          </Button>
        </AccordionDetails>
      </Accordion>
      {/* Add more accordions as needed */}
      {/* <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Lens Type</Typography>
        </AccordionSummary>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Low-Light Imaging</Typography>
        </AccordionSummary>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Illumination Distance</Typography>
        </AccordionSummary>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Environmental Protection</Typography>
        </AccordionSummary>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Power Supply</Typography>
        </AccordionSummary>
      </Accordion> */}
    </Box>
  );
};

export default FilterSidebar;