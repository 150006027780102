export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

export const FETCH_PRODUCT_BY_ID = 'FETCH_PRODUCT_BY_ID';
export const FETCH_PRODUCT_BY_ID_SUCCESS = 'FETCH_PRODUCT_BY_ID_SUCCESS';
export const FETCH_PRODUCT_BY_ID_FAILURE = 'FETCH_PRODUCT_BY_ID_FAILURE';

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';

export const FETCH_LAYOUTS = 'FETCH_LAYOUTS';
export const FETCH_LAYOUTS_SUCCESS = 'FETCH_LAYOUTS_SUCCESS';
export const FETCH_LAYOUTS_FAILURE = 'FETCH_LAYOUTS_FAILURE';

export const FETCH_BREADCRUMBS = 'FETCH_BREADCRUMBS';
export const FETCH_BREADCRUMBS_SUCCESS = 'FETCH_BREADCRUMBS_SUCCESS';
export const FETCH_BREADCRUMBS_FAILURE = 'FETCH_BREADCRUMBS_FAILURE';

