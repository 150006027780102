import React from "react";
import { Container, Typography, Box, Paper, Grid, Button, TextField } from "@mui/material";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

const HEIGHT = window.innerHeight;
const WIDTH = window.innerWidth;

const TrackNow = () => {
  return (
    <>
      <Header />
      <Box
        style={{
          width: (WIDTH * 90) / 100,
          marginLeft: (WIDTH * 5) / 100,
          paddingTop: (HEIGHT * 5) / 100,
          borderRadius: 10,
          backgroundColor: "white",
          marginBottom: (HEIGHT * 6) / 100,
          paddingBottom: (HEIGHT * 10) / 100,
        }}
      >
        <Typography
          variant="h4"
          style={{ textAlign: "center", marginBottom: (HEIGHT * 4) / 100 }}
        >
          Track Your Order
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 4 }}>
              <Typography variant="h6" gutterBottom>
                Track your recent orders from Credenze
              </Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: (HEIGHT * 2) / 100 }}
              >
                Enter your order ID to check the status and get real-time
                updates on your shipment. Stay informed with live tracking
                details for all your photography gear and equipment.
              </Typography>
              <TextField
                label="Order ID"
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: (HEIGHT * 2) / 100 }}
              >
                Track Now
              </Button>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginTop: (HEIGHT * 2) / 100 }}
              >
                Note: Tracking information is updated every 24 hours. For any
                inquiries, contact Credenze support.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default TrackNow;
