import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL1 } from "../redux/apis";

const BASE_URL = `${API_BASE_URL1}/carts`;

// CartItem interface
interface CartItem {
  cartItemId?: number;
  product: {
    productId: number;
    productName: string;
    salePrice: number;
  };
  quantity: number;
  unitPrice: number;
  totalPrice: number;
}

// Cart interface
interface Cart {
  cartId?: number;
  sessionId: string | null;
  cartItems: CartItem[];
  customerId: number | null;
}

// CartState interface with separate fields for single and multiple carts
interface CartState {
  cart: Cart | null;
  cartList: Cart[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: CartState = {
  cart: null,
  cartList: [],
  status: "idle",
  error: null,
};

const token = sessionStorage.getItem("token");

// Fetch all carts
export const fetchCarts = createAsyncThunk("carts/fetchCarts", async () => {
  const response = await axios.get(
    `${BASE_URL}?offset=0&limit=10&sortingField=1`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
});

// Fetch cart by ID
export const fetchCartById = createAsyncThunk(
  "carts/fetchCartById",
  async (cartId: number) => {
    const response = await axios.get(`${BASE_URL}/${cartId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  }
);

// Add new cart
export const addNewCart = createAsyncThunk(
  "carts/addNewCart",
  async (initialCart: Cart, { rejectWithValue }) => {
    try {
      const response = await axios.post(BASE_URL, initialCart, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          //Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Error adding to cart");
    }
  }
);

// Update existing cart
export const updateCart = createAsyncThunk(
  "carts/updateCart",
  async (initialCart: Cart) => {
    const { cartId } = initialCart;
    try {
      const response = await axios.put(`${BASE_URL}/${cartId}`, initialCart, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (err) {
      return initialCart;
    }
  }
);

// Delete cart
export const deleteCart = createAsyncThunk(
  "carts/deleteCart",
  async (cartId: number, { rejectWithValue }) => {
    try {
      await axios.delete(`${BASE_URL}/${cartId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return cartId;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Error deleting cart");
    }
  }
);

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    clearCurrentCart: (state) => {
      state.cart = null; // Clears the single cart state
    },
    addItem: (state, { payload }) => {
      if (state.cart) {
        const existingItemIndex = state.cart.cartItems.findIndex(
          (item) => item.product.productId === payload.productId
        );

        if (existingItemIndex !== -1) {
          // If item already exists, increase quantity
          const existingItem = state.cart.cartItems[existingItemIndex];
          existingItem.quantity += 1;
          existingItem.totalPrice += payload.salePrice;
        } else {
          // Add new item to cart with initial count of 1
          state.cart.cartItems.push({
            cartItemId: Math.random(),
            product: payload,
            quantity: 1,
            unitPrice: payload.salePrice,
            totalPrice: payload.salePrice,
          });
        }
      }
    },
    removeItem: (state, { payload }) => {
      if (state.cart) {
        const index = state.cart.cartItems.findIndex(
          (item) => item.product.productId === payload.productId
        );
        if (index !== -1) {
          // Remove item from cartItems array
          state.cart.cartItems.splice(index, 1);
        }
      }
    },
    modifyItem: (state, { payload }) => {
      if (state.cart) {
        const index = state.cart.cartItems.findIndex(
          (item) => item.product.productId === payload.productId
        );
        if (index !== -1) {
          // Update item count and recalculate total price
          const cartItem = state.cart.cartItems[index];
          cartItem.quantity = payload.quantity;
          cartItem.totalPrice = cartItem.unitPrice * payload.quantity;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchCarts
      .addCase(fetchCarts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCarts.fulfilled, (state, action: PayloadAction<Cart[]>) => {
        state.status = "succeeded";
        state.cartList = action.payload; // Set cartList with fetched carts
      })
      .addCase(fetchCarts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch carts";
      })

      // Handle fetchCartById
      .addCase(
        fetchCartById.fulfilled,
        (state, action: PayloadAction<Cart>) => {
          state.cart = action.payload; // Set single cart
        }
      )

      // Handle addNewCart
      .addCase(addNewCart.fulfilled, (state, action: PayloadAction<Cart>) => {
        if (Array.isArray(state.cartList)) {
          state.cart = action.payload;
          state.cartList.push(action.payload);
        } else {
          console.error("cartList is not an array", state.cartList);
        }
      })

      // Handle updateCart
      .addCase(updateCart.fulfilled, (state, action: PayloadAction<Cart>) => {
        const index = state.cartList.findIndex(
          (item) => item.cartId === action.payload.cartId
        );
        if (index !== -1) {
          state.cartList[index] = action.payload; // Update cart in list if it exists
        }
        state.cart = action.payload; // Update single cart
      })

      // Handle deleteCart
      .addCase(deleteCart.fulfilled, (state, action: PayloadAction<number>) => {
        state.cartList = state.cartList.filter(
          (item) => item.cartId !== action.payload
        ); // Remove from list
        if (state.cart && state.cart.cartId === action.payload) {
          state.cart = null; // Clear single cart if it was deleted
        }
      });
  },
});

export const { clearCurrentCart, addItem, removeItem, modifyItem } =
  cartSlice.actions;

// Selectors
export const selectAllCarts = (state: { cart: CartState }) =>
  state.cart.cartList;
export const selectCurrentCart = (state: { cart: CartState }) =>
  state.cart.cart;
export const getCartsStatus = (state: { cart: CartState }) => state.cart.status;
export const getCartsError = (state: { cart: CartState }) => state.cart.error;

export default cartSlice.reducer;
