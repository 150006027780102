import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  Checkbox,
  ListItemAvatar,
  Avatar,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchCarts } from "../../redux/cartSlice";
import { useNavigate } from "react-router-dom";

const HEIGHT = window.innerHeight;
const WIDTH = window.innerWidth;

// Define types for cart items and products
interface Product {
  productId: number;
  productName: string;
  salePrice: number;
  oldPrice?: number;
  imgSrc?: string;
}

interface CartItem {
  cartItemId: number;
  product: Product;
  quantity: number;
  totalPrice: number;
  unitPrice: number;
}

interface CartItemsProps {
  cartData: CartItem[];
  buttonText: string;
  handleNavigation: any;
  showCombo: boolean;
}

const OrderSummary: React.FC<CartItemsProps> = ({ cartData, buttonText, handleNavigation, showCombo }) => {

  const totalAmount = cartData ? cartData.reduce((sum, item) => sum + item.totalPrice, 0) : 0.00;
  const salePrice = cartData ? cartData.reduce((sum, item) => sum + item.totalPrice, 0) : 0.00;
  const totalQuantity = cartData ? cartData.reduce((sum, item) => sum + item.quantity, 0) : 0;

  return (
    <Box p={WIDTH < 400 ? 0 : 3}>
      <Paper elevation={3} sx={{ padding: 2, borderRadius: 2 }}>
        <Typography variant="h5"><strong>Your Order</strong></Typography>
        <List>
          <ListItem>
            <ListItemText
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                "& .MuiListItemText-primary": {
                  marginRight: 2,
                  fontWeight: 'bold'
                },
                "& .MuiListItemText-secondary": {
                  fontWeight: 'bolder'
                }
              }}
              primary="Total Amount"
              // secondary="₹10,651.00"
              secondary={`${salePrice}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                "& .MuiListItemText-primary": {
                  marginRight: 2,
                  fontWeight: 'bold'
                },
                "& .MuiListItemText-secondary": {
                  fontWeight: 'bolder'
                }
              }}
              primary="Total Quantity"
              // secondary="₹1,918.00"
              secondary={`${totalQuantity}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                "& .MuiListItemText-primary": {
                  marginRight: 2,
                  fontWeight: 'bold'
                },
                "& .MuiListItemText-secondary": {
                  fontWeight: 'bolder'
                }
              }}
              primary="Total Shipping"
              secondary="₹0.00"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                "& .MuiListItemText-primary": {
                  marginRight: 2,
                  fontWeight: 'bold'
                },
                "& .MuiListItemText-secondary": {
                  fontWeight: 'bolder'
                }
              }}
              primary="Total Coupon Discount"
              secondary="₹0.00"
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                "& .MuiListItemText-primary": {
                  marginRight: 2,
                  fontWeight: 'bold'
                },
                "& .MuiListItemText-secondary": {
                  fontWeight: 'bolder'
                }
              }}
              primary="Total(GST)"
              // secondary="₹12,569.00"
              // secondary={`₹${cartData.totalPrice.toFixed(2) || "-"}`}
              secondary={`₹${totalAmount.toFixed(2)}`}
            />
          </ListItem>
        </List>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mb: 2, backgroundColor: "#DC2626" }}
          onClick={handleNavigation}
          disabled={!cartData || cartData.length == 0}
        >
          <strong>{buttonText}</strong>
        </Button>

        {showCombo && cartData && cartData.length > 0 && <>
          <Typography variant="h6" marginTop={4}>
            Essential Combo
          </Typography>
          <List>
            <ListItem style={{
              paddingLeft: 0
            }}>
              <Checkbox />
              <ListItemAvatar>
                <Avatar src="https://via.placeholder.com/40" />{" "}
                {/* Replace with actual image URL */}
              </ListItemAvatar>
              <ListItemText
                sx={{
                  "& .MuiListItemText-primary": {
                    fontSize: '14px',
                    fontWeight: 'bold'
                  },
                  "& .MuiListItemText-secondary": {
                    fontSize: '10px',
                    fontWeight: 'bolder'
                  }
                }}
                primary="BMP Outdoor AI Surveillance Camera"
                secondary="₹10,651.00"
              />
            </ListItem>
            <ListItem style={{
              paddingLeft: 0
            }}>
              <Checkbox />
              <ListItemAvatar>
                <Avatar src="https://via.placeholder.com/40" />{" "}
                {/* Replace with actual image URL */}
              </ListItemAvatar>
              <ListItemText sx={{
                "& .MuiListItemText-primary": {
                  fontSize: '14px',
                  fontWeight: 'bold'
                },
                "& .MuiListItemText-secondary": {
                  fontSize: '10px',
                  fontWeight: 'bolder'
                }
              }}
                primary="Professional Installation Service"
                secondary="₹1,918.00"
              />
            </ListItem>
            <ListItem style={{
              paddingLeft: 0
            }}>
              <Checkbox />
              <ListItemAvatar>
                <Avatar src="https://via.placeholder.com/40" />{" "}
                {/* Replace with actual image URL */}
              </ListItemAvatar>
              <ListItemText sx={{
                "& .MuiListItemText-primary": {
                  fontSize: '14px',
                  fontWeight: 'bold'
                },
                "& .MuiListItemText-secondary": {
                  fontSize: '10px',
                  fontWeight: 'bolder'
                }
              }} primary="DIY Installation Kit" secondary="₹0.00" />
            </ListItem>
          </List>

          <Typography variant="h6" marginTop={4}>
            Installation Services
          </Typography>
          <List>
            <ListItem style={{
              paddingLeft: 0
            }}>
              <Checkbox />
              <ListItemAvatar>
                <Avatar src="https://via.placeholder.com/40" />{" "}
                {/* Replace with actual image URL */}
              </ListItemAvatar>
              <ListItemText sx={{
                "& .MuiListItemText-primary": {
                  fontSize: '14px',
                  fontWeight: 'bold'
                },
                "& .MuiListItemText-secondary": {
                  fontSize: '10px',
                  fontWeight: 'bolder'
                }
              }}
                primary="Basic Installation Package"
                secondary="₹999.00"
              />
            </ListItem>
            <ListItem style={{
              paddingLeft: 0
            }}>
              <Checkbox />
              <ListItemAvatar>
                <Avatar src="https://via.placeholder.com/40" />{" "}
                {/* Replace with actual image URL */}
              </ListItemAvatar>
              <ListItemText sx={{
                "& .MuiListItemText-primary": {
                  fontSize: '14px',
                  fontWeight: 'bold'
                },
                "& .MuiListItemText-secondary": {
                  fontSize: '10px',
                  fontWeight: 'bolder'
                }
              }}
                primary="Advanced Installation Package"
                secondary="₹1,199.00"
              />
            </ListItem>
            <ListItem style={{
              paddingLeft: 0
            }}>
              <Checkbox />
              <ListItemAvatar>
                <Avatar src="https://via.placeholder.com/40" />{" "}
                {/* Replace with actual image URL */}
              </ListItemAvatar>
              <ListItemText sx={{
                "& .MuiListItemText-primary": {
                  fontSize: '14px',
                  fontWeight: 'bold'
                },
                "& .MuiListItemText-secondary": {
                  fontSize: '10px',
                  fontWeight: 'bolder'
                }
              }}
                primary="Custom Installation Services"
                secondary="₹1,699.00"
              />
            </ListItem>
            <Box mt={4}>
              <Button variant="contained" color="primary" fullWidth>
                Add 5 Items to Cart
              </Button>
            </Box>
          </List>
        </>}

      </Paper>

      <Paper
        elevation={3}
        sx={{ padding: 2, borderRadius: 2, marginTop: (HEIGHT * 0.4) / 100 }}
      >
        <Typography variant="h6" marginTop={1}>
          1 Exciting Offers Are Waiting For You!
        </Typography>

        <Box
          sx={{
            display: "flex",
            gap: 2,
            mt: 1,
            borderColor: "#DDDDDD",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 3,
          }}
          border={1}
        >
          <img
            src={require("../../../assets/tags.png")}
            alt="Main Product"
            style={{ width: "20px", height: "20px", marginLeft: "10px" }}
          />
          <TextField
            sx={{
              width: (WIDTH * 20) / 100,
              alignItems: "center",
              "& fieldset": {
                border: "none",
              },
              "&:hover fieldset": {
                border: "none",
              },
              "&.Mui-focused fieldset": {
                border: "none",
              },
              "& .MuiInputBase-input": {
                height: "15px",
              },
            }}
            label="Enter Coupon Code"
          />
          <Button variant="contained" color="primary" style={{ height: 45 }}>
            Apply
          </Button>
        </Box>

        <Typography variant="body2" marginBottom={1} mt={1}>
          <strong>Available Coupons</strong>
        </Typography>
        <List
          style={{
            backgroundColor: "#F6FFF5",
            borderStyle: "dotted",
            borderColor: "#ABE292",
            padding: 0,
          }}
        >
          <ListItem>
            <ListItemAvatar>
              <img src={require("../../../assets/cpn.png")} />{" "}
              {/* Replace with actual image URL */}
            </ListItemAvatar>
            <ListItemText
              style={{ color: "#3BB77E" }}
              sx={{
                "& .MuiListItemText-primary": {
                  fontWeight: 'bold'
                },
                "& .MuiListItemText-secondary": {
                  fontSize: "11px",
                  fontWeight: 'bold'
                }
              }}
              primary="CCTV100"
              secondary="Get flat 10% off on CCTV Cameras (Min cart value Rs. 10000)"
            />
          </ListItem>
        </List>

        <Button
          variant="contained"
          color="secondary"
          fullWidth
          sx={{ mt: 2, backgroundColor: "#3BB77E", margin: 0 }}
          disabled={!cartData || cartData.length == 0}
        >
          CLICK HERE TO APPLY THE CODE
        </Button>
      </Paper>
    </Box>
  );
};

export default OrderSummary;
